const Copyright = () => {
    const currentYear = new Date().getFullYear(); 
    return(
        <>
            <div className='copyright'>
                <p>Copyright ©️ 2022 - {currentYear} by  Franciscan Sisters of Our Lady Of Graces | Created By <a href="https://entab.in" target="_blank">Entab Infotech: CampusCare®️</a>   </p>
            </div>
        </>
    )
}
export default Copyright