import React from "react";
import Copyright from '../components/Copyright'
import { Link } from 'react-router-dom'; 

const Footer = () => {
    
    return(
        <>
        <footer>
            <div className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4">
                            <img src="https://webapi.entab.info/api/image/FSOLG/public/Images/ftr-logo.png" className="img-fluid" alt="Franciscan Sisters of Our Lady Of Graces" />
                        </div>
                        <div className="col-xl-3 col-lg-3">
                            <div className="quick-link">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><Link to='/news'>Latest News</Link></li>
                                    <li><Link to='/events'>Upcoming Events</Link></li>
                                    <li><Link to='/contact'>Contact Us</Link></li> 
                                </ul>
                            </div>
                        </div>
                        
                        
                        <div className="col-xl-3 col-lg-3">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3488.6327190376483!2d77.6777172!3d29.027861400000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c65a543ada663%3A0xae4f64819874f9b!2sFSLG%20Generalate%20Kanker%20Khera!5e0!3m2!1sen!2sin!4v1681456066142!5m2!1sen!2sin" width="100%" height="150"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-xl-2 col-lg-2 pos-rel">
                             <img src="https://webapi.entab.info/api/image/FSOLG/public/Images/main-img.png" className="main-img" alt="Franciscan Sisters of Our Lady Of Graces" />
                        </div>

                    </div>
                </div>
                
            </div>
            
          
            </footer>  <Copyright />
        </>
    )
}
export default Footer;