import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import LazyLoad from "react-lazyload";

const Achievements = () => {
  return (
    <>
      <Header />
      <Breadcrumb
        pageTitle="Achievements"
        mid="Gallery"
        breadcrumbName="Achievements"
      />
      <div class="innerpage">
        <div className="container">
          <div class="row administration-blk">  
          <div className="col-md-12">
                <div className="inr-news-blk acheivement_blk">
                    <img src="https://webapi.entab.info/api/image/FSOLG/public/Images/acheivement.jpg" className="img-fluid ach-img" />
                  
                    <div className="inr-news-content"> 
                        {/* <h3 className="sub_title">News Title</h3> */}
                        <p>Congratulations to our dear Sister Sudeepti on her incredible achievement of topping IIMT College in Post B.Sc. Nursing.  She is awarded the Gold Medal in the presence of Honorable Defence Minister, Mr. Rajnath Singh.  
</p>                    
<p>May God continue to bless  you, dear Sister as you carry out the healing ministry of Jesus.</p>
<h6>Sister Sindu FSLG <br />
Superior General</h6>
                    </div>
                </div>
              </div>
          
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Achievements;
